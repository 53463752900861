
import React from 'react'
import { consuimerInfo } from './config';

function renderConsumerDom(arr) {
    if(!arr) return;
    return arr.map((item,key) => {
        return (
            <div className={`item ${item}`} key={key}></div>  
        )
    })
}

const top = renderConsumerDom(consuimerInfo.slice(0,12));
const bottom = renderConsumerDom(consuimerInfo.slice(13, 24));

export default function HomeConsumer() {

    return (
        <div className="home-consumer">
            <h3>500 + 行业领导者的选择</h3>
            <div className="consumer-wrap">
                <div className="consumer-swiper">
                    <div className="swiper-top">{top}{top}</div>
                    <div className="swiper-bottom">{bottom}{bottom}</div>
                </div>
            </div>
            <div className="consumer-mask"></div>
        </div>
    )
}