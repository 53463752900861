import fetch from '../common/fetch/index';


const MD = require("duiba-stat/dist/normal");

let env = 'duiba';
const href = window.location.href;
if (~href.indexOf('pre')) {
    env = 'duibapre';
} else if (~href.indexOf('test')) {
    env = 'duibatest';
} else if (~href.indexOf('dev')) {
    env = 'duibadev';
}
console.log(env);

MD.default.setupAjax(payload =>
    // 点击埋点发送到开发者后台（//hd.dlp.duiba.com.cn）统一处理
    // 曝光埋点发送到专门的曝光服务器（//embedlog.duiba.com.cn）处理
    fetch(payload.url === '/log/click' ? `//hd.dlp.${env}.com.cn${payload.url}` : `/exposure/standard`, {
        method: 'get',
        params: {
            dpm: payload.data.dpm,
            dcm: payload.data.dcm
        }
    })
);

function statLevelOne({
    incrementor = 0,
    dcm = "229.0.0.0",
    dpmPrefix = "1.243"
}) {
    const domain = "";
    const getMDList = items =>
        items.map((item = {}, index) => ({
            ele: item.ele,
            data: {
                dpm: `${dpmPrefix}.${incrementor}.1`,
                dcm,
                domain
            }
        }));
    return items => {
        const MDList = getMDList(items).filter(item => item.ele);
        return MD.default({ show: MDList, click: MDList });
    };
}
// 电话咨询按钮
export function telephoneCounseling() {
    return items =>
        statLevelOne({
            incrementor: 1,
            dcm: "229.0.0.0"
        })(items);
}
// 免费使用
export function freeTrial() {
    return items =>
        statLevelOne({
            incrementor: 2,
            dcm: "229.0.0.0"
        })(items);
}
//积分运营
export function creditOperations() {
    return items =>
        statLevelOne({
            incrementor: 3,
            dcm: "229.0.0.0"
        })(items);
}
//签到运营
export function signOperations() {
    return items =>
        statLevelOne({
            incrementor: 4,
            dcm: "229.0.0.0"
        })(items);
}
//互动营销
export function interactionOperations() {
    return items =>
        statLevelOne({
            incrementor: 5,
            dcm: "229.0.0.0"
        })(items);
}
// 定制服务
export function customOperations() {
    return items =>
        statLevelOne({
            incrementor: 6,
            dcm: "229.0.0.0"
        })(items);
}

// 试用表单埋点
export function trailFormMD(selector, dpm_d) {
  const MDList = [];
  MDList.push({
    ele: `${selector}`,
    data: {
      dpm: `1.136.666.${dpm_d}`,
      dcm: `225.1.0.0`,
      domain: '',
    }
  });
  MD.default({ show: MDList, click: MDList });
}