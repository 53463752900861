import React, { Component } from 'react';
import { connect } from 'react-redux';

// import {Redirect} from 'react-router-dom';
// actions
import { add, remove, addAsync, logout } from '../../actions/index';

// components
import HomeBanner from './HomeBanner';
import HomeData from './HomeData';
import HomeTitle from './HomeTitle';
import HomeIntro from './HomeIntro';
import HomeConsumer from './HomeConsumer';
import HomeServices from './HomeServices';
import HomeFooter from './HomeFooter';
import HomeButton from './HomeButton';
import IssueModal from './IssueModal';
// 埋点
import {
  telephoneCounseling,
  freeTrial,
  creditOperations,
  signOperations,
  interactionOperations,
  trailFormMD,
  customOperations
} from '../../utils/stat.js';
import { fetchGetShareInfo, doBaiduPost } from '../../common/api/index';

import './index.less';

const wx = window.wx;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issueShow: false,
      issueType: 'before'
    };
    Object.assign(this, {
      shareConfig: this.shareConfig.bind(this),
      getShareConfig: this.getShareConfig.bind(this)
    });
  }
  componentWillMount() {
    this.getShareConfig();
  }
  componentDidMount() {
    // 电话咨询
    telephoneCounseling()([
      {
        ele: '#tel-button'
      }
    ]);
    // 免费试用
    freeTrial()([
      {
        ele: '#tryout-button'
      }
    ]);
    // 积分运营
    creditOperations()([
      {
        ele: '.home-intro #m-tabs-0-0'
      }
    ]);
    // 签到运营
    signOperations()([
      {
        ele: '.home-intro #m-tabs-0-1'
      }
    ]);
    // 互动营销
    interactionOperations()([
      {
        ele: '.home-intro #m-tabs-0-2'
      }
    ]);
    // 定制服务
    customOperations()([
      {
        ele: '.home-intro #m-tabs-0-3'
      }
    ]);
    trailFormMD('.baipi-button', 7);
    trailFormMD('.tryout-button', 8);
    trailFormMD('.question-button', 9);
  }
  getShareConfig() {
    const params = {
      url: encodeURIComponent(window.location.href.split('#')[0])
    };
    fetchGetShareInfo(params)
      .then(res => {
        const { success, wxappid, wxtimestamp, wxnonceStr, wxsignature } = res;
        if (success) {
          const config = { wxappid, wxtimestamp, wxnonceStr, wxsignature };
          this.shareConfig(config);
        } else {
          console.log(`success fail: ${res}`);
        }
      })
      .catch(error => console.log(`getShareInfo error: ${error}`));
  }
  shareConfig(config = {}) {
    const { wxappid, wxtimestamp, wxnonceStr, wxsignature } = config;
    wx &&
      wx.config({
        appId: wxappid,
        timestamp: wxtimestamp,
        nonceStr: wxnonceStr,
        signature: wxsignature,
        jsApiList: [
          'checkJsApi',
          'onMenuShareAppMessage',
          'onMenuShareTimeline'
        ]
      });
    wx &&
      wx.error(function(err) {
        console.log('信息验证失败:' + err.errMsg);
      });
    wx &&
      wx.ready(function() {
        wx &&
          wx.checkJsApi({
            jsApiList: [
              'checkJsApi',
              'onMenuShareAppMessage',
              'onMenuShareTimeline'
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function(res) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              console.log(res);
            }
          });
        const { protocol, hostname } = window.location;

        const url = `${protocol}//${hostname}/share.html`;

        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.2.0）
        wx &&
          wx.onMenuShareAppMessage({
            title: '乘风而上——兑吧五周年策划纪实', //标题
            desc: '兑吧五周年终极秘密', //描述
            link: url, //链接
            imgUrl:
              'https://yun.duiba.com.cn/duiba-h5-website-node/share-icon.jpg', //图片
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function(res) {
              console.log(res);
            }
          });
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.2.0）
        wx &&
          wx.onMenuShareTimeline({
            title: '乘风而上——兑吧五周年策划纪实', //标题
            link: url, //链接
            imgUrl:
              'https://yun.duiba.com.cn/duiba-h5-website-node/share-icon.jpg', //图片
            success: function(res) {
              console.log(res);
            }
          });
      });
  }

  showIssueModal() {
    this.setState({ issueShow: true, issueType: 'before' });
  }
  closeIssueModal() {
    this.setState({ issueShow: false });
  }
  changeIssueType(type) {
    this.setState({ issueType: type });
  }

  onCall() {
    console.log('call');
    // 百度统计
    doBaiduPost({
      transformData: JSON.stringify({
        conversionTypes: [{
            logidUrl: window.location.href,
            newType: 2
          }]
        })
    });
  }

  render() {
    const { issueShow, issueType } = this.state;
    return (
      <div className='home'>
        <HomeBanner />
        <HomeData />
        <HomeTitle title={'极致服务体系  搭建运营生态'} />
        <HomeIntro />
        <HomeConsumer />
        <HomeTitle title={'专注用户运营  提供全链路服务'} />
        <HomeServices />
        <HomeFooter />
        <HomeButton showIssue={this.showIssueModal.bind(this)} />
        <a className="question-button" onClick={this.onCall} href="tel:400-180-5688"> </a>
        <IssueModal
          show={issueShow}
          issueType={issueType}
          close={this.closeIssueModal.bind(this)}
          changeIssueType={this.changeIssueType.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    num: state.counter
  };
};
const actionCreater = { add, remove, addAsync, logout };
Home = connect(
  mapStateToProps,
  actionCreater
)(Home);

export default Home;
