import React from 'react';

export default function IssueModal(props) {
  const { show, close, changeIssueType, issueType } = props;

  return (
    <div className='issue-modal' style={{ display: show ? 'block' : 'none' }}>
      <section className='issue-modal-content'>
        <div className='icon' onClick={close}></div>
        <h3>请选择问题类型</h3>
        <ul>
          <li
            className={issueType === 'before' ? 'active' : ''}
            onClick={() => changeIssueType('before')}
          >
            线上商城/活动用户咨询
          </li>
          <li
            className={issueType === 'after' ? 'active' : ''}
            onClick={() => changeIssueType('after')}
          >
            客户售后问题咨询
          </li>
        </ul>
        <div style={{ display: issueType === 'before' ? 'block' : 'none' }}>
          <p>用户咨询请拨打：400-180-5688</p>
          <a className='issue-modal-button' href='tel:400-180-5688'>
            拨打电话
          </a>
        </div>
        <div style={{ display: issueType === 'after' ? 'block' : 'none' }}>
          <p>请添加售后服务QQ：2851653869</p>
          <span
            className='issue-modal-button'
            onClick={close}
          >
            我知道了
          </span>
        </div>
      </section>
    </div>
  );
}
