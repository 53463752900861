
import React, { Component } from 'react';
import qs from 'qs';


import { createForm } from 'rc-form';

// 埋点
import { trailFormMD } from '../../utils/stat.js';

// components
import Application from '../application';

import './index.less';

class NewHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { key: '0', value: '提升用户活跃度', check: false },
                { key: '1', value: '降低平台流失率', check: false },
                { key: '2', value: '低成本消耗积分', check: false },
                { key: '3', value: '爆款活动', check: false },
                { key: '4', value: '积分运营规划', check: false },
                { key: '5', value: '裂变增长方案', check: false },
            ],
            disabled: false,
            showResult: false,
            type: '',
        };
    }

    componentDidMount() {
        document.querySelector('body').className = 'newhome';
        // window.addEventListener('scroll', this.handleVisible);

        trailFormMD('.picture1 img', 12);
        trailFormMD('.long-wrap', 13);
        trailFormMD('.picture2 img', 14);
        trailFormMD('.picture3 img', 15);
        trailFormMD('.picture4 img', 16);
        trailFormMD('.picture5 img', 17);
        trailFormMD('.picture6 img', 18);
        trailFormMD('.picture7 img', 19);
        trailFormMD('.picture8 img', 23);
        trailFormMD('.btn-baipi', 21);
        trailFormMD('.btn-try', 22);

        // 获取移动端投放渠道
        const isNew = window.location.hash.includes('/new');
        const { from } = qs.parse(window.location.search.slice(1));
        const type = isNew ? 'baidu' : (from || '');
        sessionStorage.h5Channel = sessionStorage.h5Channel || type;

    }

    componentWillUnmount() {
        document.querySelector('body').className = '';
        // window.removeEventListener('scroll', this.handleVisible)
    }

    // handleVisible() {
    //     const $wrap = document.querySelector('.long-wrap');
    //     const offsetTop = $wrap.offsetTop;
    //     const scrollTop = document.documentElement.scrollTop;
    //     if (scrollTop >= offsetTop- 20) {
    //         $wrap.style.overflow = 'auto';
    //     } else {
    //         $wrap.style.overflow = 'hidden';
    //     }
    //     if (scrollTop >= offsetTop - 20 && scrollTop <= offsetTop) {
    //         document.querySelector('html').style.overflow = 'hidden';
    //         setTimeout(() => {
    //             document.querySelector('html').style.overflow = 'auto';
    //         });
    //     }
    // }

    handleClick(item, key) {
        const { data } = this.state
        data[key].check = !data[key].check;
        this.setState({
            data
        })
    }



    render() {
        const isNew = window.location.hash.includes('/new');
        return (
            <div className="NewHome">
                <div className="picture picture1"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home1-2.png" alt="" /></div>
                <div className="form">
                    <p>获取案例及免费试用</p>
                    <Application home={true} isNew={ isNew }></Application>
                </div>
                <div className="picture picture2"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home2-2.png" alt="" /></div>a
                {/* <div className="picture picture3"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home3-2.png" alt="" /></div> */}
                <div className="picture picture4"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home4-2.png" alt="" /></div>
                <div className="picture picture5"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home5-2.png" alt="" /></div>
                <div className="picture picture8"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home8-2.png" alt="" /></div>
                {/* <div className="picture picture6"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home6-2.png" alt="" /></div> */}
                <div className="picture picture7"><img src="//yun.duiba.com.cn/duiba-h5-website-node/newhome/home7-2.png" alt="" /></div>

                <div className="btn-wrap">
                    <a className=" btn btn-baipi" href={isNew ? "?new=true#/application?from=baipi" : "#/application?from=baipi"}>
                        <span>领取行业案例</span>
                        {/* <p>（QQ音乐、蒙牛、央视频等）</p> */}
                    </a>
                    <a  href={isNew ? "?new=true#/application" : "#/application"} className="btn btn-try">
                        服务试用
                        <div className="icon"></div>
                    </a>
                </div>
            </div >
        )
    }
}

const BasicInputWrapper = createForm()(NewHome);
export default BasicInputWrapper;