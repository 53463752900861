
import React from 'react';


export default function HomeData()  {
        return (
            <div className="home-data">
                <div className="data-con left-con">
                    <div className="item item1"><span></span></div>
                    <div className="item item2"><span></span></div>
                    <div className="item item3"><span></span></div>
                </div>
                <div className="data-con right-con">
                    <div className="item item1"><span></span></div>
                    <div className="item item2"><span></span></div>
                    <div className="item item3"><span></span></div>
                </div>
            </div>
        )
}