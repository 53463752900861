import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';

export default function SubmitResult(props) {
    const { mobile, new: newStr } = qs.parse(window.location.search.slice(1));

    return (
        <div className="submit-result" style={{'display': props.show ? 'block' : 'none'}}>
            <div className="icon"></div>
            <h3>领取成功</h3>
            <p>兑吧工作人员将与您联系，请保持电话畅通哦～</p>

            {!mobile ?
                newStr === 'true' ? <Link to="/new" className="go-home" >返回首页</Link> : <Link to="/" className="go-home" >返回首页</Link>
                : ''}

        </div>
    )
}
