
import React from 'react'

import { Tabs } from 'antd-mobile';

import { introInfo } from './config';

const tabs = [
    { title: '积分运营' },
    { title: '签到运营' },
    { title: '互动营销' },
    { title: '定制服务' },
];


export default function HomeIntro() {
    const Info = introInfo.map((item, key) => {
        return (
            <div key={key} className={`${item.name} intro-wrap`}>
                <div className="intro-banner"></div>
                <div className="item">
                    {
                        item.content.map((i, k) => {
                            return (
                                <div className={`intro-item item${k+1}`} key={k}>
                                    <div className="intro-icon">
                                        <span></span>
                                    </div>
                                    <div className="intro-con">
                                        <div className="title">{i.title}</div>
                                        <div className="subtitle">{i.subtitle}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    })
    return (
        <div className="home-intro">
            <Tabs tabs={tabs}
                initalPage={0}
                useOnPan={false}
                distanceToChangeTab={0.6}
            >
                {Info}
            </Tabs>
        </div>
    )
}