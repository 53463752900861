
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

// import {Redirect} from 'react-router-dom';
// actions
import { add, remove, addAsync, logout } from '../../actions/index';
import { List, InputItem, Toast, Button } from 'antd-mobile';
import { createForm } from 'rc-form';

import { fetchSubmit, doBaiduPost } from '../../common/api/index';

// 埋点
import { trailFormMD } from '../../utils/stat.js';

// components
import SubmitResult from './SubmitResult';

import './index.less';

class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { key: '0', value: '提升用户活跃度', check: false },
                { key: '1', value: '降低平台流失率', check: false },
                { key: '2', value: '低成本消耗积分', check: false },
                { key: '3', value: '爆款活动', check: false },
                { key: '4', value: '积分运营规划', check: false },
                { key: '5', value: '裂变增长方案', check: false },
            ],
            disabled: false,
            showResult: false,
            type: '',
        };


        Object.assign(this, {
            onSubmit: this.onSubmit.bind(this),
            validateAccount: this.validateAccount.bind(this),
            handleClick: this.handleClick.bind(this),
            onFocus: this.onFocus.bind(this)
        })
    }

    componentDidMount() {
        const { from } =  qs.parse(this.props.location ? this.props.location.search.slice(1) : '')
        const { mobile, path } = qs.parse(window.location ? window.location.search.slice(1) : '');
        const fromHome = this.props.home;
        const pathname = decodeURIComponent(path);
        let dpm_d = from === 'baipi' ? 10 : 11;
        if (fromHome) {
            dpm_d = 20;
        } if (mobile && pathname === '/') {
            // PC首页
            dpm_d = 3;
        } else if (mobile && pathname.includes('/solution/integral')) {
            // 'PC积分乐园页'
            dpm_d = 6;
        }
        trailFormMD('.am-button-primary', dpm_d);
        if (mobile) {
            document.querySelector('body').className = 'pc';
        }
    }
    onSubmit() {
        const { from } =  qs.parse(this.props.location ? this.props.location.search.slice(1) : '')
        const { mobile, channel } = qs.parse(window.location.search.slice(1));
        const fromHome = this.props.home;
        this.props.form.validateFields({ force: true }, (error) => {
            if (!error) {
                const { realName, phone, companyName, customDemand } = this.props.form.getFieldsValue();
                const { data } = this.state;
                let selectDemand = data.filter(item => item.check === true).map(item => item.value).join(',');
                selectDemand = from === 'baipi' ? '白皮书' : selectDemand;

                let prefix;
                const h5Channel = sessionStorage.h5Channel;
                switch(h5Channel) {
                    case 'baidu':
                        prefix = '移动端投放';
                        break;
                    case '360':
                        prefix = '360';
                        break;
                    default:
                        prefix = '移动端自然流量';
                        break;
                }
                if (fromHome) {
                    // 首页表单
                    switch(h5Channel) {
                        case 'baidu':
                            prefix = '移动端投放首页';
                            break;
                        case '360':
                            prefix = '360';
                            break;
                        default:
                            prefix = '移动端自然流量首页';
                            break;
                    }
                } else if (mobile) {
                    // PC端提交
                    prefix = decodeURIComponent(channel) || 'PC自然流量';
                }

                const params = {
                    realName: `${prefix}-${realName}`, phone: phone.replace(/\s/g, ''), companyName, customDemand, selectDemand
                }
                this.setState({ disabled: true });
                fetchSubmit(params).then(res => {
                    this.setState({ disabled: false });
                    const { success, code, desc = '请重试' } = res;
                    if(success) {
                        this.setState({
                            showResult: true
                        })
                        // 百度转化
                        let newType = 3;
                        if (from === 'baipi') {
                            // 白皮书
                            newType = 18
                        }
                        doBaiduPost({
                            transformData: JSON.stringify({
                                conversionTypes: [{
                                    logidUrl: window.location.href,
                                    newType
                                }]
                            })
                        });
                    }else {
                        if(code === '100060') {
                            Toast.info('该手机号已领取试用资格', 3);
                        }else {
                            Toast.info(`提交失败，${desc}`, 3);
                        }

                    }
                }).catch(error => {
                    this.setState({ disabled: false });
                    console.log(`error: ${error}`)
                })
            } else {
                const { phone, realName, companyName } = error;
                if (companyName) {
                    Toast.info(companyName.errors[0].message, 3);
                }
                if (phone) {
                    Toast.info(phone.errors[0].message, 3);
                }
                if (realName) {
                    Toast.info(realName.errors[0].message, 3);
                }
            }
        });
    }

    validateAccount(rule, value, callback) {
        if (value && value.length < 10 && value.length > 0) {
            callback();
        } else {
            callback(new Error('姓名不能超过10个字符'));
        }
    }
    validatePhone(rule, value, callback) {
        if (!value || value.replace(/\s/g, '').length < 11) {
            callback(new Error('请输入正确的手机号'));
        } else {
            callback();
        }
    }
    handleClick(item, key) {
        const { data } = this.state
        data[key].check = !data[key].check;
        this.setState({
            data
        })
    }
    onFocus(type) {
        this.setState({
            type
        })
    }
    onBlur(type) {
        this.setState({
            type: ''
        })
    }

    render() {
        const { from } = qs.parse(this.props.location ? this.props.location.search.slice(1) : '');
        const { getFieldProps, getFieldError } = this.props.form;
        const { data, disabled, showResult, type } = this.state;
        const fromHome = this.props.home;
        const checkList = data.map((item, key) => {
            return (
                <div className={`check-item ${item.check === true ? 'active' : ''}`} key={key} onClick={() => this.handleClick(item, key)}>
                    {item.value}
                </div>
            )
        })
        let btnText = '';
        if (fromHome) {
            btnText = '立即提交';
        } else {
            btnText = from !== 'baipi' ? '提交服务试用' : '领行业案例'
        }

        return (
            <div className="application">
                <form className={from}>
                    <List>
                        <InputItem
                            {...getFieldProps('realName', {
                                rules: [
                                    { required: true, message: '请输入姓名' },
                                    { validator: (rule, value, callback) => this.validateAccount(rule, value, callback) },
                                ],
                            })}
                            clear
                            error={!!getFieldError('realName')}
                            placeholder="请输入姓名"
                            ref={el => this.realNameFocus = el}
                            onClick={() => this.realNameFocus.focus()}
                            onFocus={() => this.onFocus('realName')}
                            onBlur={() => this.onBlur('realName')}
                            className={`real-name ${type === 'realName' ? 'active' : ''}`}
                        >姓名</InputItem>


                        <InputItem
                            {...getFieldProps('phone', {
                                rules: [
                                    { required: true, message: '请输入手机号' },
                                    { validator: (rule, value, callback) => this.validatePhone(rule, value, callback) },
                                ],
                            })}
                            clear
                            type="phone"
                            error={!!getFieldError('phone')}
                            placeholder="请输入手机号"
                            ref={el => this.phoneFocus = el}
                            onClick={() => this.phoneFocus.focus()}
                            onFocus={() => this.onFocus('phone')}
                            onBlur={() => this.onBlur('phone')}
                            className={`phone ${type === 'phone' ? 'active' : ''}`}
                        >手机号</InputItem>

                        <InputItem
                            {...getFieldProps('companyName', {
                                rules: [{ required: true, message: '请输入公司名称' }],
                                initialValue: '',
                            })}
                            clear
                            error={!!getFieldError('companyName')}
                            placeholder="请输入公司名称"
                            ref={el => this.companyNameFocus = el}
                            onClick={() => this.companyNameFocus.focus()}
                            onFocus={() => this.onFocus('companyName')}
                            onBlur={() => this.onBlur('companyName')}
                            className={`company-name ${type === 'companyName' ? 'active' : ''}`}
                        >公司名称</InputItem>

                        {from !== 'baipi' ? <div className="check-list">
                            <h4>选择您的需求</h4>
                            {checkList}
                            {/* <TextareaItem
                                {...getFieldProps('customDemand', {
                                    initialValue: '',
                                })}
                                placeholder="若有其他需求可填写在此处"
                                ref={el => this.customDemandFocus = el}
                                onClick={() => this.customDemandFocus.focus()}
                                rows={4}
                                count={200}
                                className="area-con"
                            /> */}
                        </div> : '' }

                        <Button disabled={disabled} type="primary" onClick={this.onSubmit}>{btnText}</Button>
                    </List>
                </form>

                <SubmitResult show={showResult} />
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        num: state.counter,
    }
}
const actionCreater = { add, remove, addAsync, logout };
Application = connect(mapStateToProps, actionCreater)(Application);

const BasicInputWrapper = createForm()(Application);
// ReactDOM.render(<BasicInputWrapper />, mountNode);
export default BasicInputWrapper;