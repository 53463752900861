import fetch from '../fetch/index';

let env = 'duiba';
const href = window.location.href;
if (~href.indexOf('pre')) {
    env = 'duibapre';
} else if (~href.indexOf('test')) {
    env = 'duibatest';
} else if (~href.indexOf('dev')) {
    env = 'duibadev';
} else if (~href.indexOf('localhost')) {
    env = 'duibatest';
}

// 提交诉求
export const fetchSubmit = params =>
    fetch(`//activity.m.${env}.com.cn/customActivity/homepage/submit`, {
        method: 'post',
        body: params
    })

// 获取微信签名
export const fetchGetShareInfo = params =>
    fetch(`//activity.m.${env}.com.cn/wechatShare/getShareInfo/v2`, {
        method: 'get',
        params
    })

// 百度转化统计
export const doBaiduPost = params =>
    fetch(`//activity.m.${env}.com.cn/customActivity/baidu/seo/transform`, {
        method: 'post',
        body: params
    })