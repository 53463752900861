import React from 'react';
import ReactDOM from 'react-dom';

//redex
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './reducers/index';
//router

import { HashRouter, Route, Switch, } from 'react-router-dom';
// style
import './index.css'
import 'antd-mobile/dist/antd-mobile.css'
//modules
import Home from './pages/home';
import NewHome from './pages/newhome';
import Application from './pages/application';

import { trailFormMD } from './utils/stat.js';


let store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <HashRouter >
            <Switch>
                <Route path='/new' exact component={NewHome} />
                <Route path='/' exact component={NewHome} />
                <Route path='/old' exact component={Home} />
                <Route path='/application' component={Application} />
            </Switch>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);

// 百度统计引用
var _hmt = _hmt || [];
(function() {
var hm = document.createElement("script");
hm.src = "https://hm.baidu.com/hm.js?6b7080bbc3563c8c4477a5f10eef324c";
var s = document.getElementsByTagName("script")[0];
s.parentNode.insertBefore(hm, s);
})();

trailFormMD('#nb_icon_wrap', 24);

// 360统计引用
(function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
})(window,document,"script","_qha",515952,false);
