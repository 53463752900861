
import React from 'react'


export default function HomeBanner() {
    return (
        <div className="home-banner">
            <div className="logo"></div>
            <h1>遍布全国的合作客户</h1>
            <h2>兑吧持续为更多企业提供强有力的运营支持。</h2>
            <div className="green"></div>
            <div className="purple"></div>
            <div className="yellow"></div>
            <div className="banner-map">
                <div className="ripple ripple1">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple2">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple3">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple4">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple5">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple6">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <div className="ripple ripple7">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
            </div>
        </div>
    )
}

