/*  
这个文件主要是对fetch的封装，还有就是对请求结果的封装。 非200的统一处理
200的json化（约定所有的接口采用json格式）后传递给业务逻辑层
另外对业务逻辑出错（status 200 ， success：false），直接展示errorMsg
*/
import fetch from 'isomorphic-fetch';

// require('es6-promise').polyfill();

const errorMessages = res => `${res.status} ${res.statusText}`;

function check404(res) {
    if (res.status === 404) {
        return Promise.reject(errorMessages(res));
    }
    return res;
}

function checkStatus(response) {
    const res = response.json();
    if (response.status >= 200 && response.status < 300) {
        // 业务逻辑错
        // return res.then(({ errorCode, errorMsg, success, ...rest }) => {
        //     if (errorCode && !success) {
        //         return Promise.reject({
        //             success,
        //             statusCode: errorCode || code,
        //             msg: errorMsg || ''
        //         });
        //     }
        //     return {
        //         success,
        //         errorCode,
        //         ...rest,
        //     };
        // });
        return res;
    }
    return res.then(() =>
        Promise.reject({
            statusCode: response.status,
            msg: response.statusText
        })
    );
}

function setUrlParam(keys, value, keyPostfix) {
    let keyStr = keys[0];

    keys.slice(1).forEach(key => {
        keyStr += `[${key}]`;
    });

    if (keyPostfix) {
        keyStr += keyPostfix;
    }

    return `${encodeURIComponent(keyStr)}=${encodeURIComponent(value)}`;
}

function getUrlParam(keys, object) {
    const array = [];

    if (object instanceof Array) {
        object.forEach(value => {
            array.push(setUrlParam(keys, value, '[]'));
        });
    } else if (object instanceof Object) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];

                array.push(getUrlParam(keys.concat(key), value));
            }
        }
    } else {
        if (object !== undefined) {
            array.push(setUrlParam(keys, object));
        }
    }

    return array.join('&');
}

function toQueryString(object) {
    const array = [];

    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const str = getUrlParam([key], object[key]);
            if (str !== '') {
                array.push(str);
            }
        }
    }
    return array.join('&');
}

function process(url, options = {}, needTimestmp = true) {
    let mergeUrl = needTimestmp ? `${url}?_=${Date.parse(new Date())}` : url;

    const defaultOptions = {
        method: 'post',
        credentials: 'include'
    };

    const opts = Object.assign({}, defaultOptions, { ...options });

    if (opts.method === 'get') {
        mergeUrl = mergeUrl + '&' + toQueryString(opts['params']);
    } else {
        if (opts.json) { // json格式参数
            opts.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...opts.headers
            };

            opts.body = JSON.stringify(opts.body);
        } else if (opts.formData) { // formData提交
            opts.headers = {
                'Accept': '*/*',
                ...opts.headers,

            };
            delete opts.headers['Content-Type']; // 防止传headers
        } else { // 浏览器的原生 form 表单提交
            opts.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                ...opts.headers
            };

            opts.body = toQueryString(opts.body);
        }

    }

    return { mergeUrl, opts };
}

function Fetch(url, options, needTimestmp) {
    const { mergeUrl, opts } = process(url, options, needTimestmp);
    return fetch(mergeUrl, opts)
        .then(check404)
        .then(checkStatus)
        .then(res => {
            return res;
        })
        .catch(err => {
            return err;
        });
}

export default Fetch;
