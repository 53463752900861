
import React from 'react';
import { Link } from 'react-router-dom';


export default function HomeButton(props) {
    // const { showIssue } = props;
    return (
        <div className="home-button">
            <Link  id="baipi-button" className="baipi-button" to="/application?from=baipi">
                领取行业案例
                <p>QQ音乐、蒙牛、央视频等</p>
            </Link>
            <Link id="tryout-button" className="tryout-button" to="/application">服务试用</Link>
            <div className="popover">
                限时免费
                <div className="popover-arrow"></div>
            </div>
        </div>
    )
}