
import React from 'react'

export default function HomeFooter() {
    return (
        <div className="home-footer">
            <p>售前问题咨询热线：400-180-5688</p>
            <p>线上商城/活动用户咨询热线：400-180-5688</p>
            {/* <p>邮箱：kebinqin@duiba.com.cn</p> */}
            <p>地址：浙江省杭州市文一西路98号数娱大厦 5楼</p>
            <span></span>
            <a href="http://www.beian.miit.gov.cn/"><p>兑吧 浙ICP 14017299号-2</p></a>
        </div>
    )
}