
import React from 'react'

import { servicesInfo } from './config';

const servicesItem = servicesInfo.map((item, key) => {
    return (
        <div className="item" key={key}>
            <div className="item-icon">
                <span></span>
            </div>
            <div className="title">{item.name}</div>
            <div className="content">{item.content}</div>
        </div>
    )
})

export default function HomeServices() {

    return (
        <div className="home-services">
            {servicesItem}
        </div>
    )
}