
import React from 'react'

export default function HomeTitle(props) {
    
    return (
        <div className="home-title">
            <h3>{props.title || ''}</h3>
            <span></span>
        </div>
    )
}