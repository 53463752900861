const introInfo = [
    {
        'name': 'credit',
        'content': [{
            'title': '轻松三步 创建积分商城',
            'subtitle': '接口对接、注册账号、注册应用即可创建专属积分商城'
        }, {
            'title': '建立用户积分价值感知',
            'subtitle': '支持配置天天秒杀、限时秒杀板块、一键提升积分价值感知'
        }, {
            'title': '用户活跃度提升',
            'subtitle': '优货随意选，支持用户积分兑换。平台近百款活动工具，助力积分消耗提升平台用户活跃度'
        }, {
            'title': '权益商品无限供应',
            'subtitle': '京东、网易严选等大牌独家供应，支付宝红包、手机充值卡、Q币充值，实时到账不限量'
        }]
    },
    {
        'name': 'sign',
        'content': [{
            'title': '10+款创新签到产品',
            'subtitle': '支持选择契约签到、日历签到、集卡签到、养成签到等10+款创新签到产品'
        }, {
            'title': '用户留存增长',
            'subtitle': '从签到玩法、用户心理等多方面着手，提升签到价值感知促进留存增长'
        }, {
            'title': '深度结合任务体系',
            'subtitle': '支持与平台任务体系深度结合，从签到场景出发，打造运营闭环，促进平台数据增长'
        }, {
            'title': '支持个性化定制',
            'subtitle': '支持结合企业元素、运营策略，个性化定制多种签到玩法'
        }]
    },
    {
        'name': 'interaction',
        'content': [{
            'title': '玩转用户运营',
            'subtitle': '社群营销、节日营销、热点营销等百余款营销活动，达成拉新、促活、转化核心目标'
        }, {
            'title': '促进裂变增长',
            'subtitle': '拆解裂变底层原理，全面覆盖APP端、公众号端、小程序端拉新诉求'
        }, {
            'title': '助力品牌宣传',
            'subtitle': '结合品牌特性、品牌形象，打造具有故事性的专属活动，促进品牌传播'
        }, {
            'title': '提升付费转化',
            'subtitle': '通过活动拉新促活，站内转化，形成闭环策略，提升用户付费转化率'
        }]
    },
    {
        'name': 'custom',
        'content': [{
            'title': '契合用户画像',
            'subtitle': '通过了解用户属性、画像，帮您定制精准营销策略，触达用户更高效'
        }, {
            'title': '定制创新玩法',
            'subtitle': '根据行业特性、品牌属性，定制创新玩法，助力品牌形象输出'
        }, {
            'title': '解决方案定制',
            'subtitle': '契合产品定位，根据阶段性指标定制产品、功能服务方案，有专属运营顾问一对一跟进落地'
        }, {}]
    }
]

const servicesInfo = [
    {
        'name': '一站式接入',
        'content': '只需一键注册，便可免除后顾之忧'
    },
    {
        'name': '数据驱动',
        'content': '充分挖掘数据价值，驱动产品不断升级'
    },
    {
        'name': '专业团队服务',
        'content': '7x12小时全天，为您提供贴心服务'
    },
    {
        'name': '全链路打通',
        'content': '全面打造线上线下用户增长生态系统'
    },
]

const consuimerInfo = [
    'super-species',
    'consuimer-360',
    'eral',
    'iqiyi',
    'be-cheery',
    'baidu',
    'dzdo',
    'eleme',
    'ifeng',
    'cgb',
    'gome',
    'hdl',
    'mobike',
    'moji',
    'watsons',
    'sg',
    'xmly',
    'xdf',
    'sina',
    'cmb',
    'zbcm',
    'zgf',
    'aboc',
    'qq-music',
    'uc',
]
module.exports = {
    introInfo,
    servicesInfo,
    consuimerInfo
}